<template>
	<div class="cassie-vertical-md pt-4">
		<FilterBar
			:search-query.sync="consentCollectionPreferencePageSearchQuery"
			search-query-label="Preference Page Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persistSearchQuery="changeConsentCollectionPreferencePageSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="consentCollectionPreferencePageTranslationStatusId"
					:items="translationStatusItems"
					custom-sort
					label="Status"
					@input="changeConsentCollectionPreferencePageTranslationStatusId($event)"
				/>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Preference Page Translations
			</template>
			<template #title-action>
				<Dropdown
					:value="consentCollectionPreferencePageLanguageSelected"
					label="Language"
					:items="assignedLanguagesItems"
					@input="changeConsentCollectionPreferencePageTranslationLanguageSelected($event)"
				/>
			</template>
			<template #body>
				<DataTable
					sort-desc
					sort-by="linkID"
					:headers="tableHeaders"
					:items="formattedOverviewData || []"
					@click:row="onEditClick"
				>
					<template #item.action="{ item }">
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandId)"
							tooltip-text="Edit Preference Link"
							@click="onEditClick(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View Preference Link"
							@click="onEditClick(item)"
						>
							mdi-eye
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
	</div>
</template>
<script>
import { format } from 'date-fns'
import { DATE_FORMAT } from '../../../../../../shared/utils/date-formatting.js'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'

import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../../shared/state/brands.js'
import {
	changeConsentCollectionPreferencePageSearchQuery,
	consentCollectionPreferencePageSearchQuery,
	changeConsentCollectionPreferencePageTranslationStatusId,
	consentCollectionPreferencePageTranslationStatusId,
	changeConsentCollectionPreferencePageTranslationLanguageSelected,
	consentCollectionPreferencePageLanguageSelected
} from '../../../../../../shared/state/consent-collection-preference-pages.js'
import { MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION } from '../../../../router/route-names.js'

export default {
	components: {
		SectionCard,
		FilterBar,
		DataTable,
		Dropdown,
		IconButton
	},
	props: {
		preferencePageTranslations: Object,
		userFullPermissions: Boolean,
		userHasBrand: Function,
		assignedLanguages: Array
	},
	setup () {
		return {
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeConsentCollectionPreferencePageSearchQuery,
			consentCollectionPreferencePageSearchQuery,
			changeConsentCollectionPreferencePageTranslationStatusId,
			consentCollectionPreferencePageTranslationStatusId,
			changeConsentCollectionPreferencePageTranslationLanguageSelected,
			consentCollectionPreferencePageLanguageSelected,
			MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION
		}
	},
	data () {
		return {
			NO_TRANSLATION: 'No Translation',
			TRANSLATED: 'Translated'
		}
	},
	computed: {
		tableHeaders () {
			return [
				{ text: 'ID', value: 'linkID', width: '10%' },
				{ text: 'Brand', value: 'brandName', width: '20%' },
				{ text: 'Preference Page Name', value: 'name', width: '20%' },
				{ text: 'Status', value: 'translated', width: '20%' },
				{ text: 'Last Updated', value: 'lastUpdated', width: '20%' },
				{ text: 'Action', value: 'action', sortable: false, width: '10%' }
			]
		},
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			})).filter(({ value }) => value !== DEFAULT_LANGUAGE)
		},
		formattedOverviewData () {
			return this.preferencePageTranslations
				.translations?.filter(({ linkID }) => linkID !== 0)
				.map(item => {
					const mappedItem = { ...item }
					mappedItem.translated = item.translated ? this.TRANSLATED : this.NO_TRANSLATION
					mappedItem.lastUpdated = format(new Date(item.lastUpdated), DATE_FORMAT)
					return mappedItem
				}) ?? []
		}
	},
	created () {
		changeConsentCollectionPreferencePageTranslationLanguageSelected(
			consentCollectionPreferencePageLanguageSelected.value
				? consentCollectionPreferencePageLanguageSelected.value
				: this.assignedLanguagesItems[0]?.value ?? 0
		)
	},
	methods: {
		clearFilters () {
			changeConsentCollectionPreferencePageTranslationStatusId(null)
		},
		onEditClick (item) {
			this.$router.push({
				name: MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION,
				query: {
					id: item.linkID,
					languageId: consentCollectionPreferencePageLanguageSelected.value
				}
			})
		}
	}
}
</script>
