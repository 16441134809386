<template>
	<div class="cassie-vertical-md pt-4">
		<FilterBar
			:search-query.sync="consentCollectionPreferencePageSearchQuery"
			search-query-label="Preference Page Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persistSearchQuery="changeConsentCollectionPreferencePageSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="consentCollectionPreferencePageAuthType"
					:items="authTypeFilter"
					custom-sort
					label="Authentication Type"
					@input="changeConsentCollectionPreferencePageAuthType($event)"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="$router.push({
						name: MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE,
						params: {
							userFullPermissions: userFullPermissions
						}
					})"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Preference Pages
			</template>
			<template #body>
				<DataTable
					sort-desc
					sort-by="id"
					:headers="tableHeaders"
					:items="filteredPreferencePages"
					@click:row="onEditClick"
				>
					<template #item.action="{ item }">
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandId)"
							tooltip-text="Edit Preference Page"
							@click="onEditClick(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View Preference Page"
							@click="onEditClick(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							tooltip-text="View Embed Code"
							@click.stop.prevent="onViewEmbedClick(item)"
						>
							mdi-link
						</IconButton>
						<IconButton
							v-if="shouldShowPreview(item)"
							tooltip-text="Preview link"
							@click.stop="onPreviewClick(item)"
						>
							mdi-open-in-app
						</IconButton>
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandId)"
							tooltip-text="Delete Preference Link"
							@click.stop.prevent="preferencePageToRemove = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="preferencePageToRemove"
			:entity-name="preferencePageToRemove.name"
			entity-type="Preference Page"
			@close="preferencePageToRemove = null"
			@delete="deletePreferencePage"
		/>
		<ViewPrefPageLinkModal
			v-if="prefPageToView"
			v-bind="prefPageToView"
			:assigned-language-items="assignedLanguagesItems"
			@close="prefPageToView = null"
		/>
	</div>
</template>

<script>
import ViewPrefPageLinkModal from './view-prefpage-link-modal.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../../shared/state/brands.js'
import {
	changeConsentCollectionPreferencePageSearchQuery,
	consentCollectionPreferencePageSearchQuery,
	changeConsentCollectionPreferencePageAuthType,
	consentCollectionPreferencePageAuthType
} from '../../../../../../shared/state/consent-collection-preference-pages.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import {
	MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE
} from '../../../../router/route-names.js'
import { deletePreferencePage } from '../../../../../../shared/utils/api/preference-pages.js'
import { encryptParameters } from '../../../../../../shared/utils/api/admin-portal/links/links.js'

export default {
	components: {
		SectionCard,
		FilterBar,
		DataTable,
		PrimaryActionButton,
		Dropdown,
		IconButton,
		ConfirmDeleteModal,
		ViewPrefPageLinkModal
	},
	props: {
		preferencePages: Array,
		userFullPermissions: Boolean,
		userHasBrand: Function,
		assignedLanguages: Array
	},
	setup () {
		return {
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeConsentCollectionPreferencePageSearchQuery,
			consentCollectionPreferencePageSearchQuery,
			changeConsentCollectionPreferencePageAuthType,
			consentCollectionPreferencePageAuthType,
			MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE
		}
	},
	data () {
		return {
			preferencePageToRemove: null,
			prefPageToView: null
		}
	},
	computed: {
		tableHeaders () {
			return [
				{ text: 'ID', value: 'id', width: '10%' },
				{ text: 'Brand', value: 'brandName', width: '20%' },
				{ text: 'Preference Page Name', value: 'preferencePageName', width: '20%' },
				{ text: 'Authentication Type', value: 'authType', width: '20%' },
				{ text: 'Created', value: 'createdDate', width: '20%' },
				{ text: 'Action', value: 'action', sortable: false, width: '10%' }
			]
		},
		authTypeFilter () {
			return [
				{ value: null, text: 'View All' },
				{ value: 2, text: 'Confirm Value' },
				{ value: 1, text: 'Direct' },
				{ value: 3, text: 'Prove Email' },
				{ value: 5, text: 'Provide and Prove Email' },
				{ value: 4, text: 'Prove Value' }
			]
		},
		filteredPreferencePages () {
			return this.preferencePages.filter(({ preferencePageName, brandId, authTypeId }) => {
				let check = true
				if (consentCollectionPreferencePageSearchQuery.value) check = preferencePageName.toLowerCase().includes(consentCollectionPreferencePageSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && selectedAdminPortalBrandId.value === brandId
				if (consentCollectionPreferencePageAuthType.value !== null) check = check && consentCollectionPreferencePageAuthType.value === authTypeId
				return check
			})
		},
		assignedLanguagesItems () {
			return this.assignedLanguages.map(language => ({
				...language,
				languageCode: language.languageID === 0 ? 'default' : language.languageCode,
				value: language.languageID,
				text: language.languageName
			}))
		}
	},
	methods: {
		onEditClick (preferencePageToEdit) {
			this.$router.push({
				name: MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE,
				params: {
					userFullPermissions: this.userFullPermissions && this.userHasBrand(preferencePageToEdit.brandId)
				},
				query: {
					id: preferencePageToEdit.id
				}
			})
		},
		shouldShowPreview (prefPage) {
			return !(prefPage.linkFields?.find(
				field => !field.previewValue || field.previewValue === ''
			) ?? null)
		},
		async onPreviewClick (prefPage) {
			let encryptedParams = {}
			let tempString = this.getLinkHref(prefPage)
			let hasEncryptedParams = false
			prefPage.linkFields.forEach(field => {
				if (field.isEncrypted && field.previewValue) {
					encryptedParams = { ...encryptedParams, [field.friendlyNameInLink]: field.previewValue }
					hasEncryptedParams = true
				} else {
					tempString = tempString.replace(field.replaceValueInLink, field.previewValue)
				}
			})
			if (!hasEncryptedParams) {
				window.open(tempString)
			} else {
				const { data } = await this.getEncryptedData(encryptedParams, prefPage)
				window.open(tempString.replace('[EncryptedData]', data.encryptedValue))
			}
		},
		async getEncryptedData (encryptedParams, prefPage) {
			return await encryptParameters({
				encryptionKeyId: prefPage.encryptionKeyId,
				linkParams: encryptedParams
			})
		},
		getLinkHref (prefPage) {
			return new DOMParser()
				.parseFromString(prefPage.embedLink, 'text/html')
				.getElementsByTagName('a')[0].href
		},
		onViewEmbedClick (prefPage) {
			this.prefPageToView = prefPage
		},
		async deletePreferencePage () {
			await deletePreferencePage(this.preferencePageToRemove.emailLinkId)
			this.preferencePageToRemove = null
			this.$emit('load')
			showSnackbar('Preference Page has been removed.')
		},
		clearFilters () {
			changeConsentCollectionPreferencePageAuthType(null)
		}
	}
}
</script>
